<template>
  <div>
    <div
      v-if="loading"
      class="text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>
    <b-card v-else>
      <b-row>
        <b-row class="d-flex align-items-center justify-content-center w-100">
          <b-col cols="4" />
          <b-col cols="4">
            <h1>Accounts Tree</h1>
          </b-col>
          <b-col
            cols="4"
            class="d-flex justify-content-end"
          >
            <b-button
              ref="actions"
              variant="primary"
              class="ml-auto mr-1"
              @click="$router.push({ name: 'add-account' })"
            >
              <span class="text-nowrap">Add Account</span>
            </b-button>
          </b-col>
        </b-row>
        <b-col
          cols="3"
          md="12"
        >
          <b-tree-view
            :data="formattedAccounts"
            :rename-node-on-dbl-click="false"
            :context-menu="true"
            :context-menu-items="[ { code: 'DELETE_NODE', label: 'Delete Account' }, { code: 'RENAME_NODE', label: 'Edit Account' }, { code: 'ACTIVE_NODE', label: 'Activate Account' } ]"
            :show-icons="false"
            @nodeSelect="handleNodeSelected"
            @contextMenuItemSelect="handleContextMenuItemSelect"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { bTreeView } from 'bootstrap-vue-treeview'
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    bTreeView,
  },
  data() {
    return {
      accounts: [],
      loading: false,
      dataToRouter: {},
    }
  },
  computed: {
    formattedAccounts() {
      const formatNode = node => ({
        ...node,
        // eslint-disable-next-line eqeqeq
        name: `${node.name} -- Code: (${node.code}) -- Active: ${node.active == 1 ? '✅' : '🔴'}`,
        children: node.children ? node.children.map(formatNode) : [],
      })
      return this.accounts.map(formatNode) // Apply formatting to root nodes
    },
  },
  mounted() {
    this.showAccounts()
  },
  methods: {
    showAccounts() {
      this.loading = true

      axios
        .get('accounting-account')
        .then(res => {
          this.accounts = decryptData(res.data.payload).data.accounts
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleNodeSelected(selectedNode) {
      this.dataToRouter = {
        id: selectedNode.data.id,
        name: selectedNode.data.name,
      }
    },
    handleContextMenuItemSelect(node) {
      if (node.code === 'RENAME_NODE') {
        this.$router.push({ name: 'edit-account', params: { id: this.dataToRouter.id, name: this.dataToRouter.name } })
      } else if (node.code === 'DELETE_NODE') {
        this.$swal({
          title: 'Are you sure?',
          text: `You won't be able to revert this ${this.dataToRouter.name}!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        }).then(result => {
          this.loading = true
          if (result.isConfirmed) {
            axios
              .delete(`accounting-account/${this.dataToRouter.id}`)
              .then(() => {
                this.$swal('Deleted!', 'Deleted Successfully.', 'success')
                this.showAccounts()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            this.loading = false
          }
        })
      } else if (node.code === 'ACTIVE_NODE') {
        this.$swal({
          title: 'Are you sure?',
          text: `You will be change status for this ${this.dataToRouter.name}!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Do it!',
        }).then(result => {
          this.loading = true
          if (result.isConfirmed) {
            axios
              .put(`accounting-account/${this.dataToRouter.id}/active`)
              .then(() => {
                this.$swal('Active!', 'Active Successfully.', 'success')
                this.showAccounts()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            this.loading = false
          }
        })
      }
    },
  },
}
</script>
